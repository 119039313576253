export const config = {
  head: {
    title: 'Home Page Title',
    description: 'Home page Description Goes Here'
  },
  title: 'Heading',
  siteName: 'Site Name',
  subHeadingOne: 'Sub Heading 1',
  subHeadingOneContent: 'Sub Heading 1 Content - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  subHeadingTwo: 'Sub Heading 2',
  subHeadingTwoContent: 'Sub Heading 2 Content - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
  subHeadingThree: 'Sub Heading 3',
  subHeadingThreeContent: 'Sub Heading 3 Content - Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
}